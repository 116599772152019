import { css, SerializedStyles, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { colors } from '@/styles/constant';

export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
}
export enum ButtonSize {
  SMALL = 'SMALL',
  REGULAR = 'REGULAR',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  SMALL_TEXT = 'SMALL_TEXT',
  REGULAR_TEXT = 'REGULAR_TEXT',
}
export enum ButtonTheme {
  FILL_BROWN = 'FILL_BROWN',
  LINE_BROWN = 'LINE_BROWN',
  TEXT_BROWN = 'TEXT_BROWN',
}

export interface ButtonProps {
  /** button type */
  type?: ButtonType;
  /** button style size */
  size?: ButtonSize;
  /** button style theme */
  theme?: ButtonTheme;
  /** button full width option */
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

  customStyle?: SerializedStyles;
  children: React.ReactNode;
}

interface ButtonStyleProps {
  getSizeStyle?: SerializedStyles;
  getThemeStyle: SerializedStyles;
  customStyle?: SerializedStyles;
  isFull?: boolean;
}

const ButtonNew: React.FC<ButtonProps> = ({
  type = ButtonType.BUTTON,
  size = ButtonSize.REGULAR,
  theme = ButtonTheme.FILL_BROWN,
  isDisabled,
  isLoading = false,
  onClick,
  children,
  customStyle,
}) => {
  const getSizeStyle = ButtonSizeStyle[size];
  const getThemeStyle = ButtonThemeStyle[theme];

  return (
    <ButtonStyle
      type={type}
      disabled={isDisabled || isLoading}
      getSizeStyle={getSizeStyle}
      getThemeStyle={getThemeStyle}
      customStyle={customStyle}
      onClick={onClick}
    >
      {isLoading ? <SpinnerStyle /> : children}
    </ButtonStyle>
  );
};

export default ButtonNew;

export const ICON_WHITE = css`
  *[fill='black'] {
    fill: ${colors.WHITE};
  }

  *[stroke='black'] {
    stroke: ${colors.WHITE};
  }
`;
export const ICON_BROWN = css`
  *[fill='black'] {
    fill: ${colors.BROWN_NEW};
  }

  *[stroke='black'] {
    stroke: ${colors.BROWN_NEW};
  }
`;
export const ICON_DARK_BROWN = css`
  *[fill='black'] {
    fill: ${colors.BROWN_DARK_NEW};
  }

  *[stroke='black'] {
    stroke: ${colors.BROWN_DARK_NEW};
  }
`;

export const ButtonSizeStyle: { [index: string]: SerializedStyles } = {
  [ButtonSize.SMALL]: css`
    font-size: 12px;
    min-height: 32px;
    min-width: 40px;
    padding: 6px 8px;
  `,
  [ButtonSize.REGULAR]: css`
    font-size: 14px;
    min-height: 40px;
    min-width: 61px;
    padding: 0 16.5px;
  `,
  [ButtonSize.MEDIUM]: css`
    font-size: 16px;
    font-weight: 500;
    min-height: 52px;
    min-width: 80px;
    padding: 0 24px;
  `,
  [ButtonSize.LARGE]: css`
    font-size: 16px;
    min-height: 56px;
    min-width: 80px;
    padding: 0 24px;
  `,
  [ButtonSize.SMALL_TEXT]: css`
    font-size: 14px;
    min-height: 22px;
  `,
  [ButtonSize.REGULAR_TEXT]: css`
    font-size: 16px;
    min-height: 26px;
  `,
};
export const ButtonThemeStyle: { [index: string]: SerializedStyles } = {
  [ButtonTheme.FILL_BROWN]: css`
    background: ${colors.BROWN_NEW};
    color: ${colors.WHITE};
    font-weight: 500;
    letter-spacing: normal;
    ${ICON_WHITE};
    text-align: center;

    &:hover:enabled {
      background: ${colors.BROWN_DARK_NEW};
    }
  `,
  [ButtonTheme.LINE_BROWN]: css`
    background: ${colors.WHITE};
    border: 1px solid ${colors.BROWN_NEW};
    color: ${colors.BROWN_NEW};
    font-weight: 500;
    letter-spacing: normal;
    ${ICON_BROWN};
    text-align: center;

    &:hover:enabled {
      border: 1px solid ${colors.BROWN_DARK_NEW};
      color: ${colors.BROWN_DARK_NEW};
      ${ICON_DARK_BROWN};
    }
  `,
  [ButtonTheme.TEXT_BROWN]: css`
    color: ${colors.BROWN_NEW};
    font-weight: bold;
    letter-spacing: normal;
    ${ICON_BROWN};

    &:hover:enabled {
      color: ${colors.BROWN_DARK_NEW};
      ${ICON_DARK_BROWN};
    }
  `,
};

export const ButtonDefaultStyle = css`
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:disabled {
    opacity: 0.4;
  }

  > svg {
    margin-right: 4px;
  }
`;

const ButtonStyle = styled.button<ButtonStyleProps>`
  ${ButtonDefaultStyle};
  ${({ getSizeStyle }) => getSizeStyle};
  ${({ getThemeStyle }) => getThemeStyle};
  ${({ customStyle }) => customStyle};
`;

const SpinnerKeyFrames = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SpinnerStyle = styled.span`
  animation: ${SpinnerKeyFrames} 0.6s infinite linear;
  border-bottom: 0.3em solid rgba(255, 255, 255, 0);
  border-left: 0.3em solid #ddd;
  border-radius: 500rem;
  border-right: 0.3em solid rgba(255, 255, 255, 0);
  border-top: 0.3em solid rgba(255, 255, 255, 0);
  display: block;
  font-size: 10px;
  height: 24px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  width: 24px;

  &::after {
    border-radius: 500rem;
    height: 24px;
    width: 24px;
  }
`;
